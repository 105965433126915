import React from 'react';
import PropTypes from 'prop-types';

const propTypes = { multiplier: PropTypes.number };
const defaultProps = { multiplier: 1 };

const MultiplierTag = ({ multiplier }) => {
  if (!multiplier) return null;
  const oneIndentation = ' ';

  return (
    <div className="d-flex align-items-center">
      <div className="multiplier-tag">
        {oneIndentation}
        Lot Multiplier
        {' '}
        {multiplier}
      </div>
    </div>
  );
};

MultiplierTag.propTypes = propTypes;
MultiplierTag.defaultProps = defaultProps;

export default MultiplierTag;
