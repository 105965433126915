import React, { useCallback, useState, useMemo } from 'react';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import Modal from 'common/components/ModalComponent';
import PropTypes from 'prop-types';
import ErrorMesssage from 'common/components/ErrorMesssage';
import { buttonTypesInStrategy, handleDisabledButtonInMarketPlace } from 'ui/Strategies/helper';
import { updateRun } from 'common/api/run';
import Multiplier from '../../../../Strategies/components/Strategy/Multiplier';
import MultiplierTag from './MultiplierTag';

const propTypes = {
  multiplier: PropTypes.number.isRequired,
  runId: PropTypes.number.isRequired,
  shouldRenderMultiplierBtn: PropTypes.bool.isRequired
};
const defaultProps = { };

const MULTIPLIER_LIMIT = 50;

const LiveRunMultiplier = ({ multiplier, runId, shouldRenderMultiplierBtn }) => {
  if (!shouldRenderMultiplierBtn) return null;

  const [isShowModal, setIsShowModal] = useState(false);
  const [runMultiplier, setRunMultiplier] = useState(multiplier);

  const isInValidMultiplier = useMemo(() => runMultiplier > MULTIPLIER_LIMIT, [runMultiplier]);

  const { isDisabled } = handleDisabledButtonInMarketPlace(buttonTypesInStrategy.deployButton);

  const onUpdateRun = (key, value) => {
    updateRun({ runId, run: { [key]: value } })
      .then(() => window.location.reload())
      .catch((error) => {
        reactToastify('Something went wrong unable to Update Live Run Multiper', TOASTIFY_TYPES.ERROR);
        console.log('Live Run Multiper', error);
      });
  };

  const handelSaveModal = useCallback(() => {
    onUpdateRun('multiplier', runMultiplier || 1);
    setIsShowModal(false);
  }, [onUpdateRun, runMultiplier]);

  const renderModal = useCallback(() => {
    return (
      <Modal
        onSave={handelSaveModal}
        onClose={() => setIsShowModal(false)}
        size="md"
        okBtnTitle="Update Multiplier"
        isOkBtnDisabled={isInValidMultiplier}
        btnClassName="btn-success btn-sm"
      >
        <>
          <div className="text-center mb-2">
            <div className="alert-icons icon-info alert-info" />
            <h4 className="font-weight-medium mb-3">Live Run Multiplier</h4>
            <p>
              Multiplier per Lot
            </p>
            <div className="my-2">
              <Multiplier
                multiplier={runMultiplier}
                onChangeMultiplier={setRunMultiplier}
                maxMultiplier={MULTIPLIER_LIMIT}
              />
            </div>
            {isInValidMultiplier && (
            <ErrorMesssage
              error={{ message: `*You are exceed, Limit for multiplier is upto ${MULTIPLIER_LIMIT}` }}
            />
            )}
            <div className="tx-12 mt-2">
              {/* Can we add description about this feature hrer? */}
            </div>
          </div>
        </>
      </Modal>
    );
  }, [handelSaveModal, isInValidMultiplier, runMultiplier]);

  return (
    <>
      { isShowModal && renderModal()}
      <button
        className="btn-sm btn btn-outline-primary text-nowrap copy-btn track"
        data-toggle="tooltip"
        data-placement="top"
        onClick={() => setIsShowModal(true)}
        type="button"
        disabled={isDisabled}
        data-track-category="View Run Configs"
        data-track-action="RunConfigs Display"
        data-track-label="Show Page"
      >
        <MultiplierTag multiplier={multiplier} />
      </button>
    </>
  );
};

LiveRunMultiplier.propTypes = propTypes;
LiveRunMultiplier.defaultProps = defaultProps;

export default LiveRunMultiplier;
